import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>

    <div className="container">

      <h1>404 - Nem található</h1>
    </div>

  </Layout>
)

export default NotFoundPage
